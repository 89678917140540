/*
 * 업무구분: 활동현황
 * 화 면 명: MSPAP514P
 * 화면설명: 컨설턴트 영업/활동관리 상세조회 팝업
 * 접근권한: 지점장
 * 작 성 일: 2022.06.17
 * 작 성 자: 조한결
 */
<template>
  <ur-page-container title="상세조회" :show-title="false" type="popup">
    <ur-box-container direction="column" alignV="start">
      <ur-box-container alignV="start"  componentid="ur_box_container_001" class="ns-sch-area">

        <!-- 단체일괄동의승인 검색조건 Start -->
        <ur-box-container v-if="pSrnId === 'MSPAP508M'">
        <ur-box-container alignV="start" componentid="ur_box_container_001" direction="column" class="sch-box" >
          <div class="ns-date-picker multi">
            <!-- <mo-date-picker v-model="lv_PrdStrDate" :max-date="lv_PrdEndDate" placeholder="기간 시작일" /><span>~</span> -->
            <mo-date-picker v-model="lv_SelectStrDate" :min-date="lv_PrdStrDate" :max-date="lv_PrdEndDate" placeholder="기간 시작일" /><span>~</span>
            <mo-date-picker v-model="lv_SelectEndDate" :min-date="lv_PrdStrDate" :max-date="lv_PrdEndDate" placeholder="기간 종료일" />
          </div>
        </ur-box-container>
      </ur-box-container>
      <!-- 단체일괄동의승인 검색조건 End -->

        <ur-box-container>
        <ur-box-container alignV="start" componentid="ur_box_container_002" direction="column" class="sch-box">
          <!-- 청약서발행 & 신계약접수현황 & 모바일청약진행 & 가설발행현황 검색조건 Start -->
          <div class="ns-date-picker" v-if="pSrnId === 'MSPAP502M' || pSrnId === 'MSPAP503M' || pSrnId === 'MSPAP505M'">
            <mo-date-picker v-model="lv_PrdDate" placeholder="일자조회" />
          </div>

          <!-- FC활동/업적현황 검색조건 Start -->
          <div class="ns-date-picker multi" v-if="pSrnId === 'MSPAP506M'">
            <mo-month-picker v-model ="lv_PrdMonth" placeholder="월 조회" @confirmed="onConfirmed"/>
          </div>
        </ur-box-container>
        <ur-box-container alignV="start" componentid="ur_box_container_003" direction="column" class="sch-box" v-if="pSrnId === 'MSPAP502M' || pSrnId === 'MSPAP503M' || pSrnId === 'MSPAP504M' || pSrnId === 'MSPAP505M' || pSrnId === 'MSPAP506M' || pSrnId === 'MSPAP508M' || pSrnId === 'MSPCM113M'">
          <div class="drop-box">
            <msp-bottom-select :itemHeight="374" :items="lv_HofOrgData" v-model="lv_HofOrg.key" :itemValue="'key'" :itemText="'label'"
            @input="fn_GetDofData" underline placeholder="사업부 선택" :disabled="lv_OrgAuth.isEntpwRolYn==='N'"/>

            <msp-bottom-select :itemHeight="374" :items="lv_DofOrgData" v-model="lv_DofOrg.key" :itemValue="'key'" :itemText="'label'"
             @input="fn_GetFofData" underline placeholder="지역단 선택" :disabled="lv_OrgAuth.isHofRolYn==='N'"/>

            <msp-bottom-select :itemHeight="374" :items="lv_FofOrgData" v-model="lv_FofOrg.key" :itemValue="'key'" :itemText="'label'" class="search-fofOrg"
            @input="fn_GetFofCnsltData" underline placeholder="지점/대리점 선택" :disabled="lv_OrgAuth.isDofRolYn==='N'"/>
            <!-- MSPAP502M, MSPAP503M, MSPAP505M, MSPAP506M에서 진입 시 컨설턴트 검색 조건 Start -->
            <msp-bottom-select v-if="pSrnId === 'MSPAP502M' || pSrnId === 'MSPAP503M' || pSrnId === 'MSPAP505M' || pSrnId === 'MSPAP506M' || pSrnId === 'MSPAP508M'" :itemHeight="374" :items="lv_FofCnsltData" v-model="lv_FofCnslt.key" :itemValue="'key'" :itemText="'label'"
            @input="fn_SetReturnVal" underline placeholder="컨설턴트 전체" :disabled="lv_OrgAuth.isEofRolYn==='N'"/>
            <!-- MSPAP502M, MSPAP503M, MSPAP505M에서 진입 시 컨설턴트 검색 조건 End -->
            <!-- MSPAP504M에서 진입 시 컨설턴트 검색 조건 Start -->
            <msp-bottom-select v-if="pSrnId === 'MSPAP504M' || pSrnId === 'MSPCM113M'" :itemHeight="374" :items="[{key: '0', label: lv_FofLabel}].concat(lv_FofCnsltData.slice(1, lv_FofCnsltData.length))" v-model="lv_FofCnslt.key" :itemValue="'key'" :itemText="'label'"
            @input="fn_SetReturnVal" underline placeholder="컨설턴트 선택" :disabled="lv_OrgAuth.isEofRolYn==='N'"/>
            <!-- MSPAP504M에서 진입 시 컨설턴트 검색 조건 End -->
          <!-- FC활동/업적현황 검색조건 End -->
          </div>
        </ur-box-container>
        <!-- 정보활용동의승인 고객검색 Start -->
        <ur-box-container alignV="start" componentid="ur_box_container_005" direction="column" class="sch-box" v-if="pSrnId === 'MSPCM113M'">          
          <mo-text-field class="search-total" :disabled="lv_FofCnslt.key === '0'" v-model="lv_CustNm" searchable underline placeholder="고객명을 입력해주세요"
          @keyup.enter="fn_Open114P" @click-icon="fn_Open114P" />
        </ur-box-container>
        <!-- 정보활용동의승인 고객검색 End -->

        <ur-box-container alignV="start" componentid="ur_box_container_004" direction="column" class="sch-box" v-if="pSrnId === 'MSPAP502M' || pSrnId === 'MSPAP503M' || pSrnId === 'MSPAP504M'">
          <ur-box-container alignV="start" componentid="ur_box_container_005" direction="column" class="ns-segment">
            <mo-segment-wrapper solid primary v-model="lv_priority">
              <mo-segment-button value="1">보험료 높은 순</mo-segment-button>
              <mo-segment-button value="2">보험료 낮은 순</mo-segment-button>
            </mo-segment-wrapper>
          </ur-box-container>
        </ur-box-container>
        </ur-box-container>
        <!-- 청약서발행 & 신계약접수현황 검색조건 End -->

        <ur-box-container alignV="start" componentid="ur_box_container_006" direction="column" class="ns-btn-relative">
          <div class="relative-div">
            <mo-button componentid="mo_button_001" color="primary" shape="border" size="medium" class="ns-btn-round white" @click="fn_Clear">초기화</mo-button>
            <!-- <mo-button v-if="pSrnId === 'MSPCM113M'" componentid="mo_button_002" color="primary" shape="border" size="medium" class="ns-btn-round blue" @click="fn_Inqr">조회</mo-button> -->
            <mo-button componentid="mo_button_002" color="primary" shape="border" size="medium" class="ns-btn-round blue" @click="fn_Cvanc">조회</mo-button>
            <!-- 조회버튼 disabled 처리 예정 -->
            <!-- <mo-button :disabled="lv_HofOrg.label === '선택하세요' || lv_DofOrg.label === '선택하세요' || lv_FofOrg.label === '선택하세요'"  
            componentid="mo_button_002" color="primary" shape="border" size="medium" class="ns-btn-round blue" @click="fn_Cvanc">조회</mo-button> -->
          </div>
        </ur-box-container>
      </ur-box-container>
    </ur-box-container>
  </ur-page-container>
</template>
<script>
  /***********************************************************************************
   * 공통 라이브러리 INCLUDE 영역	                                                   *
   ***********************************************************************************/
  import MspBottomSelect from '@/ui/_cmm/components/MspBottomSelect.vue'
  import MSPBottomAlert from '@/ui/_cmm/components/MSPBottomAlert'
  import MSPAP114P from '@/ui/cm/MSPCM114P'
  import moment from 'moment';
  export default {
    /***********************************************************************************
     * Vue 파일 속성 정의 영역	                                                       *
     ***********************************************************************************/
    name: "MSPAP514P",
    screenId: "MSPAP514P",
    components: {
      MspBottomSelect
    },
    props: {
      pSrnId: String,
      pSearchData: Object
    },
    modalProps: {
      mode: 'right' // 우->좌 open popup (defalut: 하->상)
    },
    
    /***********************************************************************************
     * Life Cycle 함수 정의 영역	                                                     *
     ***********************************************************************************/
    created() {
      this.fn_OnLoad()
      this.fn_CheckIndexOf()
      this.lv_UserInfo = this.getStore('userInfo').getters.getUserInfo
      console.log('this.lv_UserInfo', this.lv_UserInfo)
    },
    mounted() {
      this.$bizUtil.insSrnLog('MSPAP514P')
      this.fn_GetFofRol()
    },
    updated() {},
    /***********************************************************************************
     * 화면변수 선언 영역	                                                             *
     ***********************************************************************************/
    data() {
      return {
      lv_SearchParam: { whlInqrYn: 'Y', hofOrgNo: '', dofOrgNo: '', fofOrgNo: '', orgNo: '', cnsltInqrYn: '' }, // 검색조건
      lv_HofOrg: {key: '00000000', label: '선택하세요'}, // 선택사업부
      lv_DofOrg: {key: '0', label: '선택하세요'}, // 선택지역단
      lv_FofOrg: {key: '0', label: '선택하세요'}, // 선택지점
      lv_FofCnslt: {key: '0', label: '전체'}, // 전체 컨설턴트
      lv_FofLabel: '선택하세요',
      lv_HofOrgData: [], // 사업부 목록
      lv_DofOrgData: [], // 지역단 목록
      lv_FofOrgData: [], // 지점 목록
      lv_FofCnsltData: [], // 컨설턴트 목록
      lv_OrgAuth: [], // 지점권한
      lv_IsFirst: true, // 최초조회 여부
      lv_IsValidate: true, // 정합성
      lv_ReturnVal: {},
      lv_IsParam: false, // 조직 파라미터가 존재 여부
      lv_PrdStrDate: moment(new Date()).subtract(7, 'days').format('YYYY-MM-DD'), // 기간 시작일
      lv_PrdEndDate: this.$bizUtil.getDateToString(new Date(), '-'), // 기간 종료일
      lv_SelectStrDate: '', // 기간시작 선택일
      lv_SelectEndDate: '', // 기간종료 선택일
      lv_PrdMonth: ['', ''], // 월 조회
      lv_PrdDate: '', // 일자조회
      lv_priority: '', // 보험료 정렬
      lv_CustNm: '', // 고객명
      lv_CnsltNo: '', // 컨설턴트 사번
      lv_IsCustNm: false, // 컨설턴트 사번
      lv_selectPriorityData: [], // 보험료 정렬
      lv_priorityData: [ // 보험료 정렬
        {'key': '01', 'label': '높은순으로'},
        {'key': '02', 'label': '낮은순으로'}
      ],
      lv_AlertPop: {},
      lv_Popup114: {},
      lv_SearchCustInfo: {},
      lv_IndexOfCheck: 0,

      lv_FOFALL_LEN: 0
    };
    },
    /***********************************************************************************
     * Computed 함수 정의 영역                                                         *
     ***********************************************************************************/
    computed: {},
    watch: {
      lv_DofOrgData () {
        if (this.lv_DofOrgData.length === 1) {
          this.lv_DofOrgData[0].label = '전체'
        }
      },
      lv_FofOrgData () {
        if (this.lv_FofOrgData.length === 1) {
          this.lv_FofOrgData[0].label = '전체'
        }
      },
      lv_FofCnsltData () {
        console.log('this.lv_FofOrgData', this.lv_FofOrgData[0])
        if (this.lv_FofOrgData[0].key === '0' || this.lv_FofCnsltData === undefined || this.lv_FofCnsltData[0].label === '전체') {
          this.lv_FofCnsltData[0] = {key: '0', label: '전체'}
        }
      },
      'lv_HofOrg.key' (newVal, oldVal) {
        if (newVal !== oldVal) {
          let findIdx = this.lv_HofOrgData.findIndex((obj) => obj.key === this.lv_HofOrg.key)
          if (findIdx !== -1) {
            this.lv_HofOrg.label = this.lv_HofOrgData[findIdx].label
          }
        }
      },
      'lv_DofOrg.key' (newVal, oldVal) {
        if (newVal !== oldVal) {
          let findIdx = this.lv_DofOrgData.findIndex((obj) => obj.key === this.lv_DofOrg.key)
          if (findIdx !== -1) {
            this.lv_DofOrg.label = this.lv_DofOrgData[findIdx].label
          }
        }
      },
      'lv_FofOrg.key' (newVal, oldVal) {
        if (newVal !== oldVal) {
          let findIdx = this.lv_FofOrgData.findIndex((obj) => obj.key === this.lv_FofOrg.key)
          if (findIdx !== -1) {
            this.lv_FofOrg.label = this.lv_FofOrgData[findIdx].label
          }
        }
      },
      'lv_FofCnslt.key' (newVal, oldVal) {
        console.log('this.lv_FofCnslt Watch :', this.lv_FofCnslt)
        if (newVal !== oldVal) {
          let findIdx = this.lv_FofCnsltData.findIndex((obj) => obj.key === this.lv_FofCnslt.key)
          if (findIdx !== -1) {
            this.lv_FofCnslt.label = this.lv_FofCnsltData[findIdx].label
            this.lv_CustNm = ''
            this.lv_SearchCustInfo = {}
          }
        }
      },
    },
    /***********************************************************************************
     * 사용자 함수 정의 영역                                                           *
     ***********************************************************************************/
    methods: {
    fn_SetReturnVal () {
      /*
      if (this.lv_OrgAuth.isEntpwRolYn === 'N' && this.lv_OrgAuth.isHofRolYn === 'N' && this.lv_OrgAuth.isDofRolYn === 'N') {
        this.disable = true
      } else {
        this.disable = false
      }
      */
      this.lv_ReturnVal = {
        hofOrg: this.lv_HofOrg,
        dofOrg: this.lv_DofOrg,
        fofOrg: this.lv_FofOrg,
        cnslt: this.lv_FofCnslt,
        disable: this.disable
      }
    },
    /******************************************************************************
    * Function명 : fn_GetFofCnsltData
    * 설명       : 컨설턴트 조회
    * 인자       :
    * Return     :
    ******************************************************************************/
    fn_GetFofCnsltData (param) {
      if ( param ) {
        this.lv_SearchParam.hofOrgNo = this.lv_HofOrg.key
        this.lv_SearchParam.dofOrgNo = this.lv_DofOrg.key
        this.lv_SearchParam.fofOrgNo = this.lv_FofOrg.key
        this.lv_SearchParam.orgNo = this.lv_FofOrg.key
        if (this.lv_FofCnsltData) { this.lv_FofCnsltData.splice(0) }
        this.lv_FofCnslt = {key: '0', label: '전체'}
        this.$bizUtil.selListFofCnslt(this.lv_SearchParam, this.fn_OrgSearchCallBack)
      } else {
        if (this.lv_DofOrg.key === 'FOF_ALL') {
          this.lv_FofOrgData = [{key: '0', label: '전체'}]
          this.lv_FofOrg.key = '0'
          this.lv_FofCnsltData = [{key: '0', label: '전체'}]
          this.lv_FofCnslt.key = '0'
          return
        } else {
          if (!_.isEmpty(this.pSearchData) && this.pSearchData.IsSecond) {
            this.lv_SearchParam.dofOrgNo = this.pSearchData.dofOrg.key
            this.lv_SearchParam.fofOrgNo = this.pSearchData.fofOrg.key
            this.lv_SearchParam.orgNo = this.pSearchData.fofOrg.key
          } else {
            this.lv_SearchParam.hofOrgNo = this.getStore('userInfo').getters.getUserInfo.onpstHofOrgNo
            this.lv_SearchParam.dofOrgNo = this.getStore('userInfo').getters.getUserInfo.onpstDofNo
            this.lv_SearchParam.fofOrgNo = this.getStore('userInfo').getters.getUserInfo.onpstFofOrgNo
            this.lv_SearchParam.orgNo = this.getStore('userInfo').getters.getUserInfo.onpstFofOrgNo
          }
          this.$bizUtil.selListFofCnslt(this.lv_SearchParam, this.fn_OrgSearchCallBack)
        }
      }
    },
    /******************************************************************************
    * Function명 : fn_GetHofData
    * 설명       : 사업부 조회
    * 인자       :
    * Return     :
    ******************************************************************************/
    fn_GetHofData () {
      if (this.lv_DofOrgData) { this.lv_DofOrgData.slice(0) }
      if (this.lv_FofOrgData) { this.lv_FofOrgData.slice(0) }
      if (this.lv_FofCnsltData) { this.lv_FofCnsltData.slice(0) }
      this.$bizUtil.selListHofOrg(this.lv_SearchParam, this.fn_OrgSearchCallBack)
    },
    /******************************************************************************
    * Function명 : fn_GetDofData
    * 설명       : 지역단 조회
    * 인자       :
    * Return     :
    ******************************************************************************/
    fn_GetDofData ( param ) {
      // this.lv_DofOrg = {key: '0', label: '선택'} // 지역단 초기화
      if ( param ) {
        this.lv_SearchParam.hofOrgNo = this.lv_HofOrg.key
        if (this.lv_DofOrgData) { this.lv_DofOrgData.slice(0) }
        if (this.lv_FofOrgData) { this.lv_FofOrgData.slice(0) }
        if (this.lv_FofCnsltData) { this.lv_FofCnsltData.slice(0) }
        this.lv_DofOrg = {key: '0', label: '선택하세요'}
        this.lv_FofOrg = {key: '0', label: '선택하세요'}
        this.lv_FofCnslt = {key: '0', label: '전체'}
        if (this.lv_HofOrg.key !== '0' && this.lv_HofOrgData.length > 1 ) {
          this.$bizUtil.selListDofOrg(this.lv_SearchParam, this.fn_OrgSearchCallBack)
        }
      }
    },
    /******************************************************************************
    * Function명 : fn_GetFofData
    * 설명       : 지점 조회
    * 인자       :
    * Return     :
    ******************************************************************************/
    fn_GetFofData (param) {
      // this.lv_FofOrg = {key: '0', label: '선택하세요'} // 지점 초기화
      if ( param ) {
        this.lv_SearchParam.hofOrgNo = this.lv_HofOrg.key
        this.lv_SearchParam.dofOrgNo = this.lv_DofOrg.key
        if (this.lv_FofOrgData) { this.lv_FofOrgData.slice(0) }
        if (this.lv_FofCnsltData) { this.lv_FofCnsltData.slice(0) }        
        // this.lv_FofOrg = {key: '0', label: '선택하세요'}
        this.lv_FofCnslt = {key: '0', label: '전체'}
      }

      this.lv_FofOrgData = [{key: '0', label: '전체'}]
      this.lv_FofOrg.key = '0'
      if (this.lv_DofOrg.key === 'FOF_ALL') {
        this.lv_FofCnsltData = [{key: '0', label: '전체'}]
        this.lv_FofCnslt.key = '0'
      } else {
        this.lv_SearchParam.fofOrgNo = '' // 지점 조회하려면 '0'이 아니여야함
        this.$bizUtil.selListFofOrg(this.lv_SearchParam, this.fn_OrgSearchCallBack)
      }
    },
    /******************************************************************************
    * Function명 : fn_OrgSearchCallBack
    * 설명       : 시스템 옵션 조회
    * 인자       : BCOptnMngSrchISVO.userEno 사용자사번
    * Return     : BCOptnMngSrchOSVO
    ******************************************************************************/
    fn_OrgSearchCallBack ( rtnData, sType ) {
      console.log('fn_OrgSearchCallBack::' + sType)
      if (sType === 'CNSLT') {
        this.lv_FofCnsltData.splice(0)
        this.lv_FofCnsltData = rtnData
        this.lv_IsFirst = false
      } else {
        // this.lv_HofOrgData = rtnData[0] // 사업부
        // this.lv_DofOrgData = rtnData[1] // 지역단
        // this.lv_FofOrgData = rtnData[2] // 지점
        
        if (rtnData[0].length !== 1 && this.lv_OrgAuth.isHofRolYn === 'Y') {
          this.lv_HofOrgData = this.fn_OrgIndex(rtnData[0], true)
        } else {
          this.lv_HofOrgData = rtnData[0] // 사업부
        }

        if (rtnData[1].length !== 1 && this.lv_OrgAuth.isDofRolYn === 'Y' ) {
          if (this.pSrnId === 'MSPAP503M') {
            rtnData[1] = [...rtnData[1].slice(0, 1), {key: 'FOF_ALL', label: '전체'}, ...rtnData[1].slice(1)]
          }
          this.lv_DofOrgData = this.fn_OrgIndex(rtnData[1], false)
        } else {
          this.lv_DofOrgData = rtnData[1] // 지역단
        }

        if (rtnData[2].length !== 1 && this.lv_OrgAuth.isEofRolYn === 'Y' && this.lv_IndexOfCheck === -1) {
          this.lv_FofOrgData = this.fn_OrgIndex(rtnData[2], false)
        } else {
          this.lv_FofOrgData = rtnData[2] // 지점
        }
        if (this.lv_IsFirst) {
          if (this.lv_OrgAuth.isEntpwRolYn === 'N') {
            for ( let oData of rtnData[0] ) {
              if ( oData.key === this.getStore('userInfo').getters.getUserInfo.onpstHofOrgNo ) {
                this.lv_HofOrg = oData
                break
             }
            }
          }
          for ( let oData of rtnData[1] ) {
            if ( oData.key === this.getStore('userInfo').getters.getUserInfo.onpstDofNo ) {
              this.lv_DofOrg = oData
              break
            }
          }
          for ( let oData of rtnData[2] ) {
            if ( oData.key === this.getStore('userInfo').getters.getUserInfo.onpstFofOrgNo ) {
              this.lv_FofOrg = oData
              break
            }
          }
        }
        this.fn_GetFofCnsltData(false)
      }
    },
    /******************************************************************************
    * Function명 : fn_GetFofRolCallBack
    * 설명       : 시스템 옵션 조회
    * 인자       : BCOptnMngSrchISVO.userEno 사용자사번
    * {isEntpwRolYn[전사역할여부]=Y/N,
    * isHofRolYn[사업부역할여부]=Y/N,
    * isDofRolYn[지역단역할여부]=Y/N,
    * isEofRolYn[지점역할여부]=Y/N}
    * Return     : BCOptnMngSrchOSVO
    ******************************************************************************/
    fn_GetFofRolCallBack ( pData ) {
      if ( pData ) {
        this.lv_OrgAuth = pData
        console.log('this.lv_OrgAuth', this.lv_OrgAuth)
        if (!_.isEmpty(this.pSearchData) && this.pSearchData.IsSecond) {
          this.lv_SearchParam.hofOrgNo = this.pSearchData.hofOrg.key
          this.lv_SearchParam.dofOrgNo = this.pSearchData.dofOrg.key
        } else {
          this.lv_SearchParam.fofOrgNo = this.getStore('userInfo').getters.getUserInfo.onpstFofOrgNo
          this.lv_SearchParam.orgNo = this.getStore('userInfo').getters.getUserInfo.onpstFofOrgNo
        }
        this.$bizUtil.selUserOrgList(this.lv_SearchParam, this.fn_OrgSearchCallBack)
      }
    },
    /******************************************************************************
    * Function명 : fn_OrgIndex
    * 설명       : 조회해온 조직List의 0번째 label값을 '전체' -> '선택'으로 바꾸는 로직
    *              차민아 프로 요구사항( 지점 레벨까지는 선택해야 조회 가능 )
    * 인자       :
    * Return     :
    ******************************************************************************/
   fn_OrgIndex (array, pIshOrg) {
     let t_Array = _.cloneDeep(array)
     if (pIshOrg) {
      let hOrgData = {key: '00000000', label: '선택하세요'}
      let hOrgIndex = array.findIndex((obj) => obj.key === hOrgData.key)
      if (hOrgIndex !== -1) {
        t_Array[hOrgIndex].label = hOrgData.label
      }
     } else {
      let dfOrgData = {key: '0', label: '선택하세요'}
      let dOrgIndex = array.findIndex((obj) => obj.key === dfOrgData.key)
        if (dOrgIndex !== -1) {
          t_Array[dOrgIndex].label = dfOrgData.label
        }
     }
     return t_Array
   },
    /******************************************************************************
    * Function명 : fn_GetFofRol
    * 설명       : 사용자의 조직조회 권한 확인
    * 인자       :
    * Return     :
    ******************************************************************************/
    fn_GetFofRol () {
      this.$bizUtil.selFofRol({}, this.fn_GetFofRolCallBack )
    },
    /******************************************************************************
    * Function명 : fn_SetPrdDate
    * 설명       : 초기 조회날짜 세팅
    * 인자       :
    * Return     :
    ******************************************************************************/
    fn_SetPrdDate () {
      this.lv_PrdDate = moment(new Date()).format('YYYY-MM-DD')
      this.lv_PrdMonth = [String(moment(new Date()).format('YYYY')), String(moment(new Date()).format('MM'))]
      this.lv_SelectStrDate = moment(new Date()).subtract(7, 'days').format('YYYY-MM-DD')
      this.lv_SelectEndDate = this.$bizUtil.getDateToString(new Date(), '-')
    },
    /******************************************************************************
    * Function명 : fn_Inqr
    * 설명       : 조회버튼 클릭 시 emit할 데이터(MSPCM113M)
    * 인자       :
    * Return     :
    ******************************************************************************/
    fn_Inqr () {
      console.log('ㅁㅁㅁㅁㅁㅁㅁㅁMSPAP113ㅁㅁㅁㅁㅁㅁㅁㅁ')
      let lv_Vm = this
      lv_Vm.fn_ValidateCvanc()
      if (lv_Vm.lv_IsValidate) {
        console.log('514P lv_pParams ::', lv_Vm.lv_SearchCustInfo)
        lv_Vm.$emit('onPopupConfirm', lv_Vm.lv_SearchCustInfo)
      }
    },
    /******************************************************************************
    * Function명 : fn_Cvanc
    * 설명       : 조회버튼 클릭 시 emit할 데이터
    * 인자       :
    * Return     :
    ******************************************************************************/
   fn_Cvanc () {
     console.log('조회버튼 클릭')
     const lv_Vm = this
     lv_Vm.fn_ValidateCvanc()
     if (lv_Vm.lv_IsValidate) {
      if (this.lv_priority === '1') {
        lv_Vm.lv_selectPriorityData.push(lv_Vm.lv_priorityData[0])
      } else if (lv_Vm.lv_priority === '2') {
        lv_Vm.lv_selectPriorityData.push(lv_Vm.lv_priorityData[1])
      } else {
        lv_Vm.lv_selectPriorityData = []
      }
      console.log('lv_Vm.lv_PrdMonth', lv_Vm.lv_PrdMonth)
      if (Number(lv_Vm.lv_PrdMonth[1]) < 10) {
        if (lv_Vm.lv_PrdMonth[1].length < 2) {
          lv_Vm.lv_PrdMonth[1] = '0' + lv_Vm.lv_PrdMonth[1]
        }
      }

      let lv_pParams = {
        hofOrg: lv_Vm.lv_HofOrg,
        dofOrg: lv_Vm.lv_DofOrg,
        fofOrg: lv_Vm.lv_FofOrg,
        cnslt: lv_Vm.lv_FofCnslt,
        searchDate: lv_Vm.lv_PrdDate,
        searchMonth: lv_Vm.lv_PrdMonth,
        FofOrgData: lv_Vm.lv_FofOrgData,
        priority: lv_Vm.lv_selectPriorityData,
        IsSecond: true,
        selectStrDate: lv_Vm.lv_SelectStrDate,
        selectEndDate: lv_Vm.lv_SelectEndDate,
        custInfoData: lv_Vm.lv_SearchCustInfo
      }

      // 지역단 선택값이 전체라면, 안내창 후에 emit 실행
      if (this.lv_DofOrg.key === 'FOF_ALL') {
        lv_pParams.lv_FofOrg = [{key: '0', label: '전체'}]
        lv_pParams.FofOrgData = [{key: '0', label: '전체'}]
        let alerObj = this.$bottomModal.open(MSPBottomAlert, {
          properties: {
            content: '사업부 산하 지점 전체 조회하시겠습니까?<br>(1~2분 시간 소요됩니다.)'
          },
          listeners: {
            onPopupConfirm: () => {
              this.$bottomModal.close(alerObj)
              lv_Vm.fn_GetFofList()
            },
            onPopupClose: () => {
              this.$bottomModal.close(alerObj)
            }
          }
        })
        return // emit 실행 방지
      }

      console.log('514P lv_pParams ::', lv_pParams)
      lv_Vm.$emit('onPopupConfirm', lv_pParams)
    }
   },
    /******************************************************************************
    * Function명 : fn_ValidateCvanc
    * 설명       : 사업부, 지역단, 지점 미선택 시 Message 출력
    * 인자       :
    * Return     :
    ******************************************************************************/
   fn_ValidateCvanc () {
     if (this.lv_HofOrg.key === '00000000') {
       this.fn_BottomAlert('사업부를 선택해주세요')
       this.lv_IsValidate = false
       return this.lv_IsValidate
     }
     if (this.lv_DofOrg.key === '0') {
       if (this.lv_DofOrgData.length === 1) {
          this.lv_IsValidate = true
          return this.lv_IsValidate
       } else  {
         this.fn_BottomAlert('지역단을 선택해주세요')
         this.lv_IsValidate = false
         return this.lv_IsValidate
       }
     }
     
     if (this.lv_FofOrg.key === '0' && this.lv_IndexOfCheck === -1) {
       if (this.lv_FofOrgData.length === 1) {
         this.lv_IsValidate = true
         return this.lv_IsValidate
       } else {
         this.fn_BottomAlert('지점을 선택해주세요')
         this.lv_IsValidate = false
         return this.lv_IsValidate
       }
     }
     if (this.pSrnId === 'MSPAP504M' || this.pSrnId === 'MSPCM113M') {
       if (this.lv_FofCnslt.key === '0') {
         console.log('this.lv_FofCnslt.key', this.lv_FofCnslt.key)
          this.fn_BottomAlert('컨설턴트를 선택해주세요')
          this.lv_IsValidate = false
          return this.lv_IsValidate
       }
     }
     if (this.pSrnId === 'MSPCM113M') {
       if (this.lv_CustNm !== this.lv_SearchCustInfo.custNm) {
         this.fn_BottomAlert('고객명을 검색해주세요.')
         this.lv_IsValidate = false
          return this.lv_IsValidate
       }
       if (this.lv_SearchCustInfo.custNm === undefined) {
         this.fn_BottomAlert('고객명을 검색해주세요.')
         this.lv_IsValidate = false
          return this.lv_IsValidate
       }
     }
     this.lv_IsValidate = true
   },
    /******************************************************************************
    * Function명 : fn_OnLoad
    * 설명       : 초기 화면 진입 시 호출
    * 인자       :
    * Return     :
    ******************************************************************************/
    fn_OnLoad () {
      console.log('this.pSrnId', this.pSrnId)
      console.log('this.pSearchData', this.pSearchData)
      if (this.pSearchData.IsSecond) {
        this.lv_FofCnslt.key = this.pSearchData.cnslt.key
        this.lv_PrdDate = this.pSearchData.searchDate
        this.lv_PrdMonth = this.pSearchData.searchMonth
        this.lv_SelectStrDate = this.pSearchData.selectStrDate
        this.lv_SelectEndDate = this.pSearchData.selectEndDate
        this.lv_CustNm = this.pSearchData.custInfoData.custNm
        if (this.pSearchData.priority.length === 0) {
          this.lv_priority = ''
        } else if (this.pSearchData.priority[0].key === '01')  {
          this.lv_priority = '1'
        } else {
          this.lv_priority = '2'
        }
        this.lv_DofOrg.key = this.pSearchData.dofOrg.key
        this.lv_FofOrg.key = this.pSearchData.fofOrg.key
        this.lv_HofOrg.key = this.pSearchData.hofOrg.key
      } else {
        this.fn_SetPrdDate()
      }
    },
    onConfirmed(value){
      console.log("[onConfirmed] value: "+ value);
    },
    /******************************************************************************
    * Function명 : fn_CheckIndexOf
    * 설명       : 진입한 화면ID를 판단
    * 인자       :
    * Return     :
    ******************************************************************************/
    fn_CheckIndexOf () {
      if (this.pSrnId === 'MSPAP505M' || this.pSrnId === 'MSPAP506M' || this.pSrnId === 'MSPAP504M' || this.pSrnId === 'MSPAP508M'){
        this.lv_IndexOfCheck = -1
      } else {
        this.lv_IndexOfCheck = 0
      }
    },
    /******************************************************************************
    * Function명 : fn_Clear
    * 설명       : 초기화버튼 클릭 시
    * 인자       :
    * Return     :
    ******************************************************************************/
   fn_Clear () {
      console.log('inner test')
      this.$set(this, 'lv_HofOrg', {key: '00000000', label: '선택하세요'})
      this.$set(this, 'lv_DofOrg', {key: '0', label: '선택하세요'})
      this.$set(this, 'lv_FofOrg', {key: '0', label: '선택하세요'})
      this.$set(this, 'lv_FofCnslt', {key: '0', label: '전체'})
      this.$set(this, 'lv_SelectStrDate', moment(new Date()).subtract(7, 'days').format('YYYY-MM-DD'))
      this.$set(this, 'lv_SelectEndDate', this.$bizUtil.getDateToString(new Date(), '-'))
      this.$set(this, 'lv_CustNm', '')
      this.lv_priority = ''
      this.lv_IsFirst = true
      this.fn_GetFofRol()
   },
    /******************************************************************************
    * Function명 : fn_BottomAlert
    * 설명       : 알림창 팝업 오픈
    * 인자       :
    * Return     :
    ******************************************************************************/
   fn_BottomAlert (contents) {
     this.lv_AlertPop = this.$bottomModal.open(MSPBottomAlert, {
       properties: {
         single: true,
         title: '알림',
         content: contents
       },
       listeners: {
         onPopupConfirm: () => {
           this.$bottomModal.close(this.lv_AlertPop);
         },
         onPopupClose: () => {
           this.$bottomModal.close(this.lv_AlertPop);
         }
       }
     })
    },
    /******************************************************************************
    * Function명 : fn_Open114P
    * 설명       : 고객검색 팝업 오픈
    * 인자       :
    * Return     :
    ******************************************************************************/
    fn_Open114P () {
      console.log('this.lv_FofCnslt.key', this.lv_FofCnslt.key.length)
      if (this.lv_FofCnslt.key.length === 1) {
        this.fn_BottomAlert('컨설턴트를 선택하세요.')
        return
      } else {
        this.lv_CnsltNo = this.lv_FofCnslt.key
      }
      this.lv_Popup114 = this.$bottomModal.open(MSPAP114P, {
       properties: {
         title: '고객검색',
         pSrnId: 'MSPAP514P',
         pCustNm: this.lv_CustNm,
         pCnsltNo: this.lv_CnsltNo
       },
       listeners: {
         onPopupConfirm: (pData) => {
           this.$bottomModal.close(this.lv_Popup114);
           Object.assign(this.lv_SearchCustInfo, pData)
           console.log('this.lv_SearchCustInfo', this.lv_SearchCustInfo)
           this.lv_CustNm = this.lv_SearchCustInfo.custNm
          //  this.$emit('onPopupConfirm', pData)
         },
         onPopupClose: () => {
           this.$bottomModal.close(this.lv_Popup114);
         }
       }
      })
    },
    /******************************************************************************
    * Function명 : fn_IsCustInfo
    * 설명       : 고객검색팝업에서 받아온 데이터 유무 체크
    * 인자       :
    * Return     :
    ******************************************************************************/
    fn_IsCustInfo () {
        console.log('IsCustInfo test')
        if (Object.keys(this.lv_SearchCustInfo).length !== 0) {
          this.lv_CustNm = this.lv_SearchCustInfo.custNm
        } 
    },

    /******************************************************************************
    * Function명 : fn_GetFofList
    * 설명       : 사업부 산하 지점 리스트 조회
    * 인자       :
    * Return     :
    ******************************************************************************/
    fn_GetFofList () {
      const lv_Vm = this

      let pParams = {
        hofOrgNo: this.lv_HofOrg.key
      }

      const trnstId = 'txTSSBC14S5'
      const auth = 'S'
      this.post(lv_Vm, pParams, trnstId, auth)
        .then(res => {
          console.log('txTSSBC14S5', res)
          let icFofList = []
          if (_.isEmpty(res.body) || _.isEmpty(res.body.icfofVO)) {
            console.log(res.msgComm.msgDesc)
            return
          }
          res.body.icfofVO.forEach(item => {
            if (item.key !== '0' || item.key !== 'FOF_ALL') {
              icFofList.push({key: item.orgNo, label: item.orgNm})
            }
          })
          let lv_pParams = {
            hofOrg: lv_Vm.lv_HofOrg,
            dofOrg: lv_Vm.lv_DofOrg,
            fofOrg: icFofList,
            cnslt: lv_Vm.lv_FofCnslt,
            searchDate: lv_Vm.lv_PrdDate,
            searchMonth: lv_Vm.lv_PrdMonth,
            FofOrgData: icFofList,
            priority: lv_Vm.lv_selectPriorityData,
            IsSecond: true,
            selectStrDate: lv_Vm.lv_SelectStrDate,
            selectEndDate: lv_Vm.lv_SelectEndDate,
            custInfoData: lv_Vm.lv_SearchCustInfo
          }
          lv_Vm.$emit('onPopupConfirm', lv_pParams)
        })
        .catch(error => {
          console.log(res.msgComm.msgDesc)
          window.vue.error(error)
        })
    },
  } // End Methods::
  };
</script>
<style scoped>
</style>